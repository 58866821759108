<template>
  <div class="promotion-banner">
    <div class="left">
      <div v-if="isLogin" class="login" :class="{ vip: isVip }">
        <div class="user-info" @click="handleGoMine">
          <div class="avatar">
            <img :src="userInfo.avatar" />
            <span class="vip-icon"></span>
          </div>
          <div class="nickname">{{ userInfo.username }}</div>
          <p v-if="notActivityFreeVip">{{ vipDesc }}</p>
          <p v-else>限时会员：{{ remainingTime }}</p>
        </div>
        <div class="vip-entry" @click="handleClickPromotion">
          <div class="left">
            <template v-if="isExpire || isVip">
              <h3>
                {{ isVip ? '倾心回馈，感恩一路有你' : '会员权益全新升级！' }}
              </h3>
              <p>
                {{ isVip ? '续费低至' : '8大权益低至' }}<span>0.2元/天</span>
              </p>
            </template>
            <template v-else>
              <h3 class="new">
                您有一张VIP卡待领取
                <!-- <van-count-down
                  :time="12 * 3600 * 1000"
                  @finish="handleTimeFinish1"
                  :auto-start="true"
                >
                  <template #default="timeData">
                    <span class="block">{{ timeData.hours }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ String(timeData?.minutes).length > 1 ? timeData?.minutes : '0' + timeData?.minutes}}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds == 0 ? '00' : timeData.seconds }}</span>
                  </template>
                </van-count-down> -->
              </h3>
              <p><i style="color: #d80000">8</i>项会员权益，尽享奢华体验</p>
            </template>
          </div>
          <img
            v-if="isExpire"
            src="https://qncweb.ktvsky.com/20240802/vadd/9f9d0e1419020195f446a0b336fea4fa.png"
            alt=""
          />
          <img
            v-else
            src="https://qncweb.ktvsky.com/20240802/vadd/7e33c688febad919768a3c73e9fa599b.png"
            alt=""
          />
        </div>
      </div>

      <div v-else class="unlogin">
        <div class="title">
          <img
            src="https://qncweb.ktvsky.com/20240619/other/a4ccac2587fd29333344a5e6691bd58b.png"
            alt=""
          />
        </div>
        <div class="flex">
          <div class="text">
            <div
              v-for="item in texts"
              :key="item"
              class="text-item"
              v-html="item"
            ></div>
          </div>
          <div class="code">
            <div class="qrcode">
              <img :src="qrCodeURL" />
            </div>
            <div class="tip">微信扫码登录</div>
          </div>
        </div>
      </div>

      <!-- three days free vip -->
      <!-- <div v-else class="unlogin-vip-free-card">
        <div class="code">
          <div class="qrcode">
            <img :src="qrCodeURL" />
          </div>
          <div class="tip">微信扫码登录</div>
        </div>
      </div> -->
    </div>
    <!-- <div class="right horizontal">
      <div class="banner" @click="handleClickBanner">
        <img src="https://qncweb.ktvsky.com/20240710/other/a17e4bd56f5d4304b080002e5a634561.png" class="horizontal">
      </div>
    </div> -->
  </div>
</template>

<script>
import useQRCode from '@/composables/useQRCode'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import { payLogFrom } from '@/log/pay'
import { getCarplayInfo } from '@/service/carplay-info'
import { addDays, differenceInMilliseconds, isAfter, parseISO } from 'date-fns'
import get from 'lodash/get'
import { CountDown, Toast } from 'vant'
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: {
    [CountDown.name]: CountDown,
  },
  setup() {
    const { getQRCodeURL } = useQRCode()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { showVipQrcode } = useVip()

    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const userInfo = computed(() => store.state.userInfo)
    const vipInfo = computed(() => store.state.vipInfo)
    const isLogin = computed(() => !!userInfo.value.unionid)
    // const freeVipNumber =computed(() => Number(store.state.freeVipNumber))
    const isExpire = computed(() => vipInfo.value.expire)
    const isClimaxFree = computed(() => !store.state.climax.freeSing)
    const isShowClimax = computed(() => !isVip.value && isClimaxFree)
    const userType = computed(() => store.state.userInfo.userType)

    const vipFreeActivity = computed(() => store.state.act.vipFreeActivity)

    const notActivityFreeVip = computed(() => {
      if (!vipFreeActivity.value) return true
      const activityEndTime = addDays(parseISO(vipFreeActivity.value), 3)
      return isAfter(new Date(), activityEndTime)
    })

    const vipDesc = computed(() => {
      if (isVip.value) {
        const endDate = vipInfo.value.end_time
          .split(' ')[0]
          .replaceAll('-', '.')
        return `会员有效期至: ${endDate}`
      }
      return '您的会员已过期'
    })

    const remainingTime = ref('')
    let timer = null

    const activityVipEndTime = computed(() => {
      if (!vipFreeActivity.value) return null
      return addDays(parseISO(vipFreeActivity.value), 3)
    })

    const updateRemainingTime = () => {
      if (!activityVipEndTime.value) {
        if (timer) {
          clearInterval(timer)
          timer = null
        }
        remainingTime.value = ''
        return
      }

      const now = new Date()
      const diff = differenceInMilliseconds(activityVipEndTime.value, now)
      console.log(activityVipEndTime.value, 'activityVipEndTime.value test')
      console.log(now, 'now test')
      console.log(diff, 'diff test')
      if (diff <= 0) {
        remainingTime.value = '00:00:00'
        if (timer) {
          clearInterval(timer)
          timer = null
        }
        // update vip info
        store.dispatch('getCarplayInfo')
        // 开启开屏弹窗
        showVipQrcode({
          log: '我的入口-头像',
          isLogin: isLogin.value,
          fr: 1891,
        })

        store.dispatch('act/resetVipFreeActivity')
        return
      }

      const hours = Math.floor(diff / 3600000)
      const minutes = Math.floor((diff % 3600000) / 60000)
      const seconds = Math.floor((diff % 60000) / 1000)

      remainingTime.value = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    watch(vipFreeActivity, (val) => {
      console.log(val, 'vipFreeActivity')
      if (val) {
        updateRemainingTime()
        if (timer) clearInterval(timer)
        timer = setInterval(updateRemainingTime, 1000)
      }
    })

    onMounted(() => {
      // test
      // const now = new Date();
      // const test = "2024-09-17 18:30:08";
      // const testDate = parseISO(test);
      // const testdate1 = addDays(testDate, 3);
      // const testDiff = differenceInMilliseconds(testdate1, now);
      // console.log(testdate1, "testdate1");
      // console.log(now, "testnow");
      // console.log(testDiff, "testDiff");

      updateRemainingTime()
      timer = setInterval(updateRemainingTime, 1000)
    })

    // const preferentialTime = ref(30 * 60 * 1000);
    // const timeData = ref({});
    // const userTime = ref(null);

    onUnmounted(() => {
      if (timer) clearInterval(timer)
    })

    let qrCodeURL = ref(
      'https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png'
    )

    const texts = [
      '解锁点歌特权',
      'VIP歌曲免费唱<span>1次</span>',
      '快唱高潮免费体验<span>30min</span>',
      '经典专属歌单',
      'VIP限时优享<span>0.2元/天</span>',
    ]

    const banners = ['Banner 1', 'Banner 2', 'Banner 3']

    const getLoginQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'login_qr', '')) {
        const loginUrl = `${data.pay_qr}&log=${
          payLogFrom.get('我的入口-头像').m
        }`
        const qrCodeData = await getQRCodeURL(loginUrl)
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    const handleGoMine = () => {
      router.push({
        name: 'mine',
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 10010,
        event_data: {
          str1: '首页',
          str2: '我的入口',
          str3: '进入我的',
          str4: 'click',
        },
      })
    }

    const handleClickPromotion = () => {
      showVipQrcode({
        log: '扫码登录-我的',
        isLogin: isLogin.value,
        fr: 1740,
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 30237,
        event_data: {
          str1: '首页',
          str2: '用户卡片运营位',
          str3: '点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
    }

    const handleTimeFinish = () => {
      store.dispatch('climax/updateFreeSing', true)
    }

    const handleClickBanner = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: 30235,
        event_data: {
          str1: '首页',
          str2: '“特别定制”歌单',
          str3: '点击',
          str4: 'click',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        },
      })
      router.push({
        name: 'songList',
        query: {
          name: '“特”别定制，会员专享歌单',
          image:
            'https://qncweb.ktvsky.com/common-web/operation_cms/1691133786343.png',
        },
      })
    }

    onBeforeMount(() => {
      if (!isLogin.value) {
        getLoginQrcode()
      }
    })

    // watch(
    //   route,
    //   (val) => {
    //     console.log(val.name, "route");
    //     // if (val.name === 'climax') {
    //     //   nextTick(() => userTime.value.start());
    //     // } else {
    //     //   nextTick(() => userTime.value.pause());
    //     // }
    //   },
    //   { deep: true, immediate: true }
    // );

    watch(isLogin, (val) => {
      if (!val) {
        getLoginQrcode()
      }
    })

    return {
      texts,
      banners,
      qrCodeURL,
      isLogin,
      userInfo,
      vipDesc,
      isVip,
      handleGoMine,
      // freeVipNumber,
      isExpire,
      isClimaxFree,
      // preferentialTime,
      // timeData,
      handleTimeFinish,
      // userTime,
      handleClickPromotion,
      isShowClimax,
      handleClickBanner,
      notActivityFreeVip,
      activityVipEndTime,
      remainingTime,
    }
  },
}
</script>

<style lang="stylus" scoped>
.promotion-banner
  // width 980px
  // height 334px
  width 484px
  height 334px
  background-color #FFFFFF1A
  border-radius 32px
  display flex
  // align-items center
  // justify-content space-between
  margin-right 24px
  position relative
  border: 1px solid rgba(255, 255, 255, 0.15)
  @media screen and (max-width 1200px) and (min-height 1200px)
    width 408px
    height 376px
    flex-direction row
    margin-right 0px
    align-items center
  .left
    // border-radius 24px
    // margin-top 10px
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex 1
      height 100%
      width 100%
      margin-top 0px
    .login
      margin 10px 12px 0px 16px
      position relative
      @media screen and (max-width 1200px) and (min-height 1200px)
        padding 64px 14px 14px
        margin 0
        position static
        font-size 16px
        height 100%
        display flex
        flex-direction column
        // justify-content center
      .vip-entry
        background: linear-gradient(92.85deg, #FAE3CD 1.33%, #E4B58E 100.32%);
        width 452px
        height 92px
        border-radius 20px
        margin 20px auto 0
        color #582D04
        font-size 20px
        padding 0 20px 0 24px
        display flex
        justify-content space-between
        align-items center
        @media screen and (max-width 1200px) and (min-height 1200px)
          transform scale(calc(380 / 536))
          width 141% !important
          transform-origin left
          margin 55px auto 0

        .left
          margin-top 0

        h3
          font-size 24px
          font-weight 700
          display flex
          align-items center
          &.new
            // font-size 19px
            .van-count-down
              color #582D04
              margin-left 8px
              .colon
                color #582D04
                margin 0 4px
                font-size 14px
              .block
                width 24px
                height 24px
                line-height 24px
                text-align center
                display inline-block
                background rgba(88, 45, 4, 0.2)
                color #582D04
                font-size 14px
                border-radius 4px
              @media screen and (max-width 1200px) and (min-height 1200px)
                .block
                  font-size 12px
                  width 22px
                  height 22px
                  line-height 22px
                .colon
                  margin 0 2px
        span
          color #D80000
          font-weight 700
        img
          width 112px
        @media screen and (max-width 1200px) and (min-height 1200px)
          width 482px
          height 90px
          margin-left 0px
          margin-top 32px
          position relative
          top 3px
          .left
            display flex
            flex-direction column
            justify-content center
          h3
            font-size 24px
          span
            font-size 20px
          img
            width 100px
      .user-info
        text-align center
        color rgba(255, 255, 255, 0.6)
        font-size 20px
        @media screen and (max-width 1200px) and (min-height 1200px)
          transform scale(calc(64 / 80))
          transform-origin center
        .avatar
          width 80px
          height 80px
          margin 0 auto
          position relative
          &:after
            content ""
            width 48px
            height 28px
            border-radius: 100px;
            background-color: #3B5160;
            position absolute
            bottom -10px
            left 50%
            margin-left -24px
            z-index 4
          img
            border-radius 50%
          .vip-icon
            width 44px
            height 24px
            position absolute
            left 50%
            margin-left -22px
            bottom -8px
            background url('https://qncweb.ktvsky.com/20240322/vadd/21860657a2183185dcef3c03f9f5c8bb.png') no-repeat
            background-size 100% auto
            z-index 5
        .nickname
          font-size 28px
          color #fff
          margin 24px auto 12px
          font-weight 500
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          max-width 385px
      &.vip
        .nickname
          color #E5B875
        .avatar
          &:before
            content: "";
            position: absolute;
            right 0
            top -11px
            width: 80px;
            height: 101px;
            background: url('https://qncweb.ktvsky.com/20240619/other/0e13e391ad99123f0dc3912cc225faaa.png');
            background-size 100%
            z-index 6
    // .unlogin-vip-free-card
    //   width 484px
    //   height 334px
    //   border-radius 32px
    //   display flex
    //   justify-content space-between
    //   align-items center
    //   position relative
    //   background url('https://qncweb.ktvsky.com/20240920/vadd/03d8e4a4f1db99da9872dc484025401e.png') no-repeat
    //   background-size 100%
    //   background-position center
    //   @media screen and (max-width 1200px) and (min-height 1100px)
    //     width 408px
    //     height 376px
    //     background url('https://qncweb.ktvsky.com/20240920/vadd/62eec60ddb7e483bc695de11f92ea39e.png') no-repeat
    //     background-size 100%
    //     background-position center
    //   .code
    //     width 152px
    //     height 177px
    //     color rgba(0, 0, 0, 0.6)
    //     font-size 18px
    //     text-align center
    //     position absolute
    //     right 39px
    //     bottom 15px
    //     @media screen and (max-width 1200px) and (min-height 1100px)
    //       right 19px
    //       bottom 27px
    //     .qrcode
    //       width 152px
    //       height 152px
    //       background #fff
    //       border-radius 15px
    //       display flex
    //       align-items center
    //       justify-content center
    //       img
    //         width 144px
    //         height 144px
    //     .tip
    //       width 152px
    //       height 20px
    //       text-align center
    //       display none
    .unlogin
      margin 18px 0 0px 36px
      @media screen and (max-width 1200px) and (min-height 1100px)
        margin 50px 0 0 28px
        .code
          margin-right 0px !important
      .title
        margin-bottom 9px
        width 336px
        height 56px
        position relative
        font-size 40px
        @media screen and (max-width 1200px) and (min-height 1100px)
          width 280px
          margin-bottom 33px
        // &::before
          // content: "";
          // position: absolute;
          // width 86px
          // height 30px
          // background url('https://qncweb.ktvsky.com/20240711/other/c2ff0686f03fba524c3502b21862d102.png') no-repeat
          // background-size 100% auto
          // right -88px
          // top -16px
      .text
        margin-top 24px
        margin-right 10px
        @media screen and (max-width 1200px) and (min-height 1100px)
          margin-right 10px
          margin-top 0px
      .text-item
        line-height 36px
        font-size 20px
        color rgba(185, 247, 255, 0.74)
        position: relative;
        padding-left 26px
        font-weight 300
        @media screen and (max-width 1200px) and (min-height 1100px)
          font-size 16px
          line-height 28px
          padding-left 18px
        ::v-deep span
          font-weight bold
        &::before
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          background-color: rgba(136, 251, 180, 1);
          border-radius: 50%;
          @media screen and (max-width 1200px) and (min-height 1100px)
            width 8px
            height 8px
      .flex
        width 100%
        display flex
        justify-content space-between
        @media screen and (max-width 1200px) and (min-height 1200px)
          align-items center
          padding-right 26px
        .code
          background #fff
          border-radius 15px
          width 160px
          height 190px
          color rgba(0, 0, 0, 0.6)
          font-size 18px
          text-align center
          margin-left 10px
          margin-right 5px
          margin-top 25px
          img
            width 144px
            height 144px
            margin 10px auto 9px
          @media screen and (max-width 1200px) and (min-height 1100px)
            width 140px
            height 166px
            font-size 16px
            margin-top 0px
            img
              width 124px
              height 124px
              margin 8px auto
  // .right
  //   width 378px
  //   height 310px
  //   display flex
  //   align-items center
  //   justify-content center
  //   margin-right 12px
  //   .banner
  //     width 100%
  //     height 100%
  //     display flex
  //     align-items center
  //     justify-content center
  //     img
  //       width 100%
  //       height 100%
  //       border-radius 20px
  //   .banner-item
  //     width 100%
  //     height 100%
  //     color #FFFFFF
  //     display flex
  //     align-items center
  //     justify-content center
  //     font-size 24px
  //   @media screen and (max-width 1200px) and (min-height 1200px)
  //     width 498px
  //     height 336px
  //     margin-left 0px
  //     .banner
  //       margin 0px 12px 0 0
</style>
